import { Controller } from "@hotwired/stimulus";
import { Dismiss } from "flowbite";

// Makes the target element disappear when the element with this controller is clicked.
export default class extends Controller {
  connect() {
    let target = document.querySelector(
      this.element.getAttribute("data-dismiss-target")
    );

    this.dismiss = new Dismiss(target, this.element);
  }
}
