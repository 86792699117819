// app/javascript/controllers/message_form_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["content"];

  submitOnEnter(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      this.element.requestSubmit();
      this.contentTarget.value = "";
    }
  }
}
