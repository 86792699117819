import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.scrollToBottom();
    this.observeMutations();
  }

  scrollToBottom() {
    this.element.scrollTop = this.element.scrollHeight;
  }

  observeMutations() {
    const observer = new MutationObserver(() => this.scrollToBottom());
    observer.observe(this.element, { childList: true, subtree: true });
    this.observer = observer;
  }

  disconnect() {
    this.observer.disconnect();
  }
}
