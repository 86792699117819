import { Controller } from "@hotwired/stimulus";
import ApexCharts from "apexcharts";

// Connects to data-controller="chart"
// expects `options` to be in the dom as json
export default class extends Controller {
  static targets = ["options"];
  connect() {
    // initialize components based on data attribute selectors
    if (
      document.getElementById("data-series-chart") &&
      typeof ApexCharts !== "undefined"
    ) {
      this.chart = new ApexCharts(
        document.getElementById("data-series-chart"),
        JSON.parse(this.optionsTarget.innerHTML)
      );
      this.chart.render();
    }
  }

  disconnect() {
    this.chart.destroy();
  }
}
